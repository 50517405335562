import { __decorate } from "tslib";
import { AbstractModal } from '../AbstractModal';
import { Component } from 'vue-property-decorator';
import { Screen } from '../../Screen';
/**
 * Basic modal that relies on the data from the `payload` @Prop.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let InfoModal = class InfoModal extends AbstractModal {
    /**
     * Handles the `@close` event on the `<D24ModalInner>` component.
     */
    onClose() {
        if (this.payload && typeof this.payload.onClose === 'function') {
            this.payload.onClose();
        }
        this.close();
    }
};
InfoModal = __decorate([
    Component({ name: 'InfoModal', components: { Screen } })
], InfoModal);
export { InfoModal };
export default InfoModal;
